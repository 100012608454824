<template>
  <div class="container" id="DisputeShow">
    <!--Header-->
    <div class="headerDisputeShow">
      <div class="font-size-xxl disputeLabel">
        {{ $t("dispute.dispute_order") }}
      </div>
      <div class="statusContainer">
        <div class="statusLabel font-size-md">{{ $t("dispute.status") }}</div>
        <div class="statusDispute font-size-lg">{{ dispute.status }}</div>
      </div>
    </div>
    <div class="disputeOrderContainer ">
      <!-- Dispute Order  -->
      <dispute-order :order="dispute.order" />

      <!--Dispute Messages-->
      <dispute-messages :dispute="dispute" :getDispute="getDispute" />
    </div>
  </div>
</template>

<script>
import DisputeOrder from "@/views/Dispute/Partials/DisputeOrder";
import DisputeMessages from "@/views/Dispute/Partials/DisputeMessages";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";
export default {
  name: "DisputeShow",
  components: { DisputeOrder, DisputeMessages },
  setup(){
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("dispute.page_title"),
    })
  },
  data() {
    return {
      dispute: {},
      loading: false
    };
  },
  methods: {
    async getDispute() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("view_dispute", {
          id: this.$route.params.id
        });
        window.axios.get(url).then(
          response => {
            resolve(response.data);
            this.dispute = response.data.data;
            this.loading = false;
          },
          error => {
            reject(error);
          }
        );
      });
    }
  },
  created() {
    this.getDispute();
  }
};
</script>

<style scoped lang="scss">
#DisputeShow {
  height: 100%;
  .headerDisputeShow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
    border-bottom: 1px solid map-get($colors, "bordering-gray");
    align-items: center;
    .disputeLabel {
      font-weight: bold;
      color: map-get($colors, "black");
    }
    .statusContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .statusLabel {
        color: map-get($colors, "secondary");
        text-transform: uppercase;
      }
      .statusDispute {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  .disputeOrderContainer {
    display: flex;
    height: 100%;
    max-height: calc(100% - 72px);
  }
}
@include media-breakpoint-down(lg) {
  #DisputeShow {
    .disputeOrderContainer {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
