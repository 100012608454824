<template>
  <div class="orderContainer">
    <div class="orderHeader">
      <img
        :src="window.Routes.getImage(order?.shop.logo, 'preview')"
        class="shopLogo"
      />
      <div class="font-size-big">{{ order?.shop.name }}</div>
    </div>

    <!-- Order Products -->
    <div
      class="productContainer"
      v-for="product in order?.ordered_products"
      :key="product.id"
    >
      <img
        :src="window.Routes.getImage(product?.product.image, 'preview')"
        class="productImage"
      />
      <div class="productDetails">
        <div class="productName font-size-big">{{ product.name }}</div>
        <div class="productExtras">
          <div
            class="font-size-md extrasData"
            v-if="product.ordered_extras?.length > 0"
          >
            {{ product.ordered_extras?.length }} {{ $t("dispute.extras") }}
            <i class="fas fa-circle mx-2 color-secondary circleIcon"></i>
          </div>
          <div class="productPrice font-size-md">
            {{ product.currency }} {{ product.price.toFixed(2) }}
          </div>
        </div>
      </div>
    </div>

    <!-- Order Details -->
    <div class="detailsContainer">
      <div class="detailLine">
        <div class="detailLabel font-size-md">{{ $t("dispute.weight") }}:</div>
        <div class="detailData font-size-big">{{ order?.total_weight }}kg</div>
      </div>
      <div class="detailLine">
        <div class="detailLabel font-size-md">
          {{ $t("dispute.payment_method") }}:
        </div>
        <div class="detailData font-size-big">{{ order?.payment_method }}</div>
      </div>
      <div class="detailLine">
        <div class="detailLabel font-size-md">
          {{ $t("dispute.shipping") }}:
        </div>
        <div class="detailData font-size-big">
          {{ order?.currency }} {{ order?.shipping_price }}
        </div>
      </div>
      <div class="detailLine">
        <div class="detailLabel font-size-md">{{ $t("dispute.total") }}:</div>
        <div class="detailData font-size-big">
          {{ order?.currency }} {{ order?.total_amount }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisputeOrder",

  props: {
    order: {
      type: Object
    }
  }
};
</script>

<style scoped lang="scss">
.orderContainer {
  display: flex;
  flex-direction: column;
  width: 315px;
  min-height: 100%;
  background-color: map-get($colors, "lightGray");
  padding: 17px 20px;
  .orderHeader {
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
    margin-bottom: 15px;
    align-items: center;
    border-bottom: 1px solid map-get($colors, "gray");
    .shopLogo {
      width: 35px;
      height: 35px;
      border-radius: 35px;
      margin-right: 10px;
    }
  }

  .productContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;

    .productImage {
      width: 45px;
      height: 45px;
      object-fit: cover;
      border-radius: 8px;
      outline: 1px solid map-get($colors, "gray");
    }
    .productDetails {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      .productName {
        font-weight: 600;
      }
      .productExtras {
        display: flex;
        flex-direction: row;
        .extrasData {
          color: map-get($colors, "secondary");
          align-items: center;
          display: flex;
          .circleIcon {
            font-size: 3px;
            color: map-get($colors, "black");
          }
        }
      }
    }
  }

  .detailsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .detailLine {
      margin-bottom: 5px;
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .detailLabel {
        color: map-get($colors, "secondary");
      }
      .detailData {
        color: map-get($colors, "black");
        font-weight: 600;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .orderContainer {
    width: 100%;
    min-height: auto;
  }
}
</style>
