<template>
  <div class="messagesContainer">
    <div class="messageSection">
      <div class="font-size-xl font-weight-bold">
        {{ $t("dispute.messages") }}
      </div>
      <!-- Dispute Reason -->
      <div
        class="boxLine"
        v-for="(message, index) in dispute.messages"
        :key="message.id"
      >
        <dispute-reason
          :dispute-reason="message"
          :messageIndex="index"
          :orderId="dispute.order.id"
          :getDispute="getDispute"
        />
      </div>
    </div>
    <div class="typeContainer">
      <div class="inputContainer">
        <div class="imageContainer" v-if="image.length > 0">
          <div class="singleImage">
            {{ image
            }}<img
              src="@/assets/close.svg"
              class="closeIcon"
              @click="image = ''"
            />
          </div>
        </div>

        <div class="inputMessage">
          <input
            v-model="messages"
            :placeholder="$t('dispute.write_replay')"
            class="inputBox"
          />
          <div v-if="image.length < 1">
            <label class="attachButton" for="uploadmyfile"
              ><img src="@/assets/attach.svg" class="uploadIcon" />
              <div class="buttonLabel">{{ $t("dispute.attach") }}</div></label
            >
            <input
              type="file"
              id="uploadmyfile"
              ref="file"
              v-on:change="handleFileChange()"
            />
          </div>
        </div>
      </div>
      <button
        class="sendButton font-size-md btn"
        @click="sendMessage"
        :disabled="messages.length < 1"
      >
        {{ $t("dispute.send") }}
      </button>
    </div>
  </div>
</template>

<script>
import DisputeReason from "@/views/Dispute/Partials/DisputeReason";
import { getBase64, thumbnailify } from "@/includes/base64";
export default {
  name: "DisputeMessages",
  components: { DisputeReason },
  data() {
    return {
      messages: "",
      files: [],
      file: "",
      image: "",
      thumbnailImage: ""
    };
  },
  props: {
    dispute: {
      type: Object,
      default: null
    },
    getDispute: {
      type: Function
    }
  },
  methods: {
    handleFileChange() {
      if (this.$refs.file.files[0] != undefined) {
        this.file = this.$refs.file.files[0];
        this.submitFile();
      }
    },
    submitFile() {
      this.thumbnailImage = "base64Thumbnail";

      let formData = new FormData();
      formData.append("file", this.file);

      window.axios
        .post(window.Routes.getRoute("upload_media"), formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(({ data }) => {
          this.image = data.name;
          getBase64(this.file).then(base64 => {
            thumbnailify(base64, 50).then(base64Thumbnail => {
              this.thumbnailImage = base64Thumbnail;
            });
          });
        });
    },
    async readyToSend() {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("send_message", {
          orderId: this.dispute.order.id
        });
        window.axios
          .post(url, {
            body: this.messages,
            images: this.image.length > 1 ? [this.image] : []
          })
          .then(
            response => {
              resolve(response.data.data);
              this.subscribed = !this.subscribed;
            },
            error => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
          );
      });
    },
    async sendMessage() {
      try {
        await this.readyToSend();
        await this.getDispute();
        this.messages = "";
      } catch (error) {
        console.log(error);
      } finally {
        console.log("finally");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.messagesContainer {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  padding: 30px 0 30px 30px;
  justify-content: space-between;
  .messageSection {
    overflow: scroll;
    padding-bottom: 20px;
  }
  .typeContainer {
    width: 100%;
    bottom: 0;
    right: 0;
    border-top: 1px solid map-get($colors, "bordering-gray");
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .inputContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      .imageContainer {
        display: flex;
        width: auto;
        margin-bottom: 5px;

        .singleImage {
          border: 1px solid map-get($colors, "gray");
          border-radius: 8px;
          padding: 3px 5px;
          .closeIcon {
            margin-left: 3px;
          }
        }
      }
    }
    .inputMessage {
      width: 100%;
      height: 48px;
      position: relative;
      align-items: center;
      .inputBox {
        border: 1px solid map-get($colors, "gray");
        border-radius: 8px;
        width: 100%;
        height: 100%;
        color: map-get($colors, "black");
        padding: 8px 90px 8px 15px;
        ::placeholder {
          color: map-get($colors, "secondary");
        }
        &:focus-visible {
          outline: none;
        }
      }
      .attachButton {
        right: 8px;
        bottom: 8px;
        top: 8px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        background-color: map-get($colors, "gray");
        border-radius: 8px;
        color: map-get($colors, "secondary");
        cursor: pointer;
        .uploadIcon {
          opacity: 0.6;
        }
      }
      #uploadmyfile {
        display: none;
      }
    }
    .sendButton {
      width: 100px;
      padding: 15px;
      background-color: map-get($colors, "twiks");
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 8px;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .boxLine {
    width: 100%;
    display: flex;
  }
}
@include media-breakpoint-down(lg) {
  .messagesContainer {
    overflow: scroll;
    padding: 20px 0;
    .typeContainer {
      .inputMessage {
        .inputBox {
          padding-right: 60px;
        }
        .buttonLabel {
          display: none;
        }
      }

      .sendButton {
        margin-left: 10px;
        padding: 14px 10px;
        width: auto;
      }
    }
  }
}
</style>
