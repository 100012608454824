<template>
  <div
    class="boxContainer"
    v-bind:class="{
      containerShopMessage: disputeReason.sender.role !== 'Customer',
      customerMessage:
        disputeReason.sender.role === 'Customer' && messageIndex !== 0
    }"
  >
    <div
      class="reasonContainer"
      v-if="disputeReason !== null"
      v-bind:class="{
        shopMessage: disputeReason.sender.role !== 'Customer',
        customerMessage:
          disputeReason.sender.role === 'Customer' && messageIndex !== 0,
        solutionShop: disputeReason.solution !== null
      }"
    >
      <img
        v-for="(image, index) in disputeReason.images"
        :key="index"
        :src="window.Routes.getImage(image, 'preview')"
        class="imageDispute"
      />
      <div class="disputeContent font-size-md">"{{ disputeReason.body }}"</div>
      <div class="bottomBox font-size-md">
        {{ disputeReason.sender.name }}
        <i class="fas fa-circle mx-2 color-secondary circleIcon"></i>
        {{
          moment(disputeReason.created_at)
            .local()
            .format("LT, d MMM YY")
        }}
      </div>
    </div>

    <!-- Offered Solution -->

    <div class="solutionContainer" v-if="disputeReason.solution !== null">
      <div class="labelSolution font-size-md">
        {{ $t("dispute.shopOffer") }}
      </div>
      <!--Refund-->
      <div class="refundContainer">
        <div class="refundLabel font-size-md">
          {{ refundMapper(disputeReason.solution.type) }}
        </div>
        <div
          class="refundData font-size-md"
          v-if="
            ['partially_refund', 'refund'].includes(disputeReason.solution.type)
          "
        >
          {{ disputeReason.solution.refund_amount }}
          {{ disputeReason.solution.currency }}of
          {{ disputeReason.solution.total_amount }}
          {{ disputeReason.solution.currency }}
        </div>
      </div>

      <!--Offered Status -->
      <div class="offeredStatus">
        <div class="statusLabel font-size-md">{{ $t("dispute.status") }}:</div>
        <div
          :style="statusToColorMapper(disputeReason.solution.status)"
          class="font-size-md"
        >
          {{ statusMapper(disputeReason.solution.status) }}
        </div>
      </div>

      <!-- Response Button -->
      <div
        class="buttonsContainer"
        v-if="disputeReason.solution.status === 'pending'"
      >
        <div class="buttonResponse" @click="respondToSolution('accepted')">
          {{ $t("dispute.accept") }}
        </div>
        <div
          class="buttonResponse declineButton"
          @click="respondToSolution('declined')"
        >
          {{ $t("dispute.decline") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisputeReason",
  props: {
    disputeReason: {
      type: Object,
      default: null
    },
    messageIndex: {
      type: Number,
      default: 0
    },
    orderId: {
      type: Number,
      default: null
    },
    getDispute: {
      type: Function
    }
  },
  methods: {
    refundMapper(solutionType) {
      switch (solutionType) {
        case "decline":
          return "Decline";
        case "partially_refund":
          return "Partially Refund";
        case "refund":
          return "Refund";
        case "resend":
          return "Resend";
        default:
          return "";
      }
    },
    statusToColorMapper(status) {
      switch (status) {
        case "declined":
          return {
            color: "#E6515D"
          };
        case "accepted":
          return { color: "#4C89F5" };
        case "pending":
          return { color: "#F2BC32" };
        default:
          return "";
      }
    },
    statusMapper(status) {
      switch (status) {
        case "declined":
          return "Declined";
        case "accepted":
          return "Accepted";
        case "pending":
          return "Pending";
        default:
          return "";
      }
    },
    async sendSolution(status) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("respond_solution", {
          orderId: this.orderId,
          solutionId: this.disputeReason.solution.id
        });
        window.axios.post(url, { status: status }).then(
          response => {
            resolve(response.data);
            this.dispute = response.data;
          },
          error => {
            reject(error);
          }
        );
      });
    },
    async respondToSolution(status) {
      try {
        await this.sendSolution(status);
        await this.getDispute();
      } catch (error) {
        console.log(error);
      } finally {
        console.log("finally");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.boxContainer {
  display: flex;
  max-width: 48%;
  flex-direction: column;
  margin-left: auto;
  margin-top: 10px;
}

.reasonContainer {
  width: 100%;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  background-color: map-get($colors, "black");
  border-radius: 8px 8px 8px 0px;
  .imageDispute {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    object-fit: cover;
  }
  .disputeContent {
    margin-top: 10px;
    color: white;
    font-weight: bold;
    word-wrap: break-word;
  }
  .bottomBox {
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    opacity: 0.6;
    justify-content: flex-end;
    .circleIcon {
      font-size: 5px;
      color: white;
    }
  }
}

.containerShopMessage {
  margin-right: auto;
  margin-left: 0;
}

.shopMessage {
  background-color: map-get($colors, "lightGray");
  border: 1px solid map-get($colors, "gray");
  justify-content: flex-start;
  .disputeContent {
    color: map-get($colors, "black");
    font-weight: 400;
    font-size: 14px;
  }
  .bottomBox {
    color: map-get($colors, "secondary");
    font-weight: 400;
    font-size: 14px;
    justify-content: flex-start;
  }
}
.customerMessage {
  background-color: map-get($colors, "secondary");
  border-radius: 8px 8px 0 8px;

  .disputeContent {
    font-weight: 400;
    font-size: 14px;
  }
  .bottomBox {
    font-weight: 400;
    font-size: 14px;
  }
}
.solutionShop {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.solutionContainer {
  border: 1px solid map-get($colors, "gray");
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  .labelSolution {
    color: map-get($colors, "black");
    font-weight: bold;
  }
  .refundContainer {
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .refundLabel {
      color: map-get($colors, "secondary");
    }
    .refundData {
      background-color: #4c89f5;
      color: white;
      margin: 0 5px;
      padding: 0 5px;
      border-radius: 5px;
    }
  }
  .offeredStatus {
    display: flex;
    flex-direction: row;

    .statusLabel {
      text-transform: capitalize;
      margin-right: 3px;
      color: map-get($colors, "black");
    }
  }
  .buttonsContainer {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    .buttonResponse {
      cursor: pointer;
      border-radius: 5px;
      width: 160px;
      padding: 7px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: map-get($colors, "twiks");
      color: white;
      margin-right: 10px;
    }
    .declineButton {
      background-color: transparent;
      border: 1px solid #d75c61;
      margin-right: 0;
      color: map-get($colors, "black");
    }
  }
}
@include media-breakpoint-down(lg) {
  .boxContainer {
    max-width: 95%;
  }
}
</style>
