const getBase64 = async function(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const thumbnailify = async function(base64Image, targetSize) {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = function() {
      const width = img.width,
        height = img.height,
        canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      canvas.width = canvas.height = targetSize;

      ctx.drawImage(
        img,
        width > height ? (width - height) / 2 : 0,
        height > width ? (height - width) / 2 : 0,
        width > height ? height : width,
        width > height ? height : width,
        0,
        0,
        targetSize,
        targetSize
      );

      resolve(canvas.toDataURL());
    };

    img.src = base64Image;
  });
};

export { getBase64, thumbnailify };
